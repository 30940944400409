import storage from 'common/storage';

var localChannel = {
    set: function (id, callback) {
        storage.localChannel = { localChannelSlug: localChannel.all[id].url, localChannelTitle: localChannel.all[id].name };
        callback(id);
    },
    get: function (callback) {
        const localStorageChannel = storage.localChannel;
        const localChannelSlug = localStorageChannel ? localStorageChannel.localChannelSlug : 'stockholm';
        callback(localChannel.getChannelFromSlug(localChannelSlug));
    },
    getChannelFromSlug(slug) {
        const channel = localChannel.allSlug[slug];
        return {
            id: channel.id,
            name: channel.name,
            url: '/' + slug
        };
    },
    getChannel: function (id) {
        return {
            id: id,
            name: localChannel.all[id].name,
            url: localChannel.all[id].url
        };
    },
    all: {
        200: { name: 'Jämtland', url: '/jamtland' },
        201: { name: 'Kalmar', url: '/kalmar' },
        202: { name: 'Sörmland', url: '/sormland' },
        203: { name: 'Jönköping', url: '/jonkoping' },
        204: { name: 'Värmland', url: '/varmland' },
        205: { name: 'Gotland', url: '/gotland' },
        206: { name: 'Sjuhärad', url: '/sjuharad' },
        207: { name: 'Malmöhus', url: '/malmo' },
        208: { name: 'Skaraborg', url: '/skaraborg' },
        209: { name: 'Norrbotten', url: '/norrbotten' },
        210: { name: 'Gävleborg', url: '/gavleborg' },
        211: { name: 'Kristianstad', url: '/kristianstad' },
        212: { name: 'Göteborg', url: '/goteborg' },
        213: { name: 'Blekinge', url: '/blekinge' },
        214: { name: 'Kronoberg', url: '/kronoberg' },
        215: { name: 'Västerbotten', url: '/vasterbotten' },
        216: { name: 'Västernorrland', url: '/vasternorrland' },
        217: { name: 'Västmanland', url: '/vastmanland' },
        218: { name: 'Uppland', url: '/uppland' },
        219: { name: 'Väst', url: '/vast' },
        220: { name: 'Halland', url: '/halland' },
        221: { name: 'Örebro', url: '/orebro' },
        222: { name: 'Östergötland', url: '/ostergotland' },
        223: { name: 'Dalarna', url: '/dalarna' },
        5283: { name: 'Södertälje', url: '/sodertalje' },
        701: { name: 'Stockholm', url: '/stockholm' }
    },
    allSlug: {
        jamtland: { name: 'Jämtland', url: '/jamtland', id: 200 },
        kalmar: { name: 'Kalmar', url: '/kalmar', id: 201 },
        sormland: { name: 'Sörmland', url: '/sormland', id: 202 },
        jonkoping: { name: 'Jönköping', url: '/jonkoping', id: 203 },
        varmland: { name: 'Värmland', url: '/varmland', id: 204 },
        gotland: { name: 'Gotland', url: '/gotland', id: 205 },
        sjuharad: { name: 'Sjuhärad', url: '/sjuharad', id: 206 },
        malmo: { name: 'Malmöhus', url: '/malmo', id: 207 },
        skaraborg: { name: 'Skaraborg', url: '/skaraborg', id: 208 },
        norrbotten: { name: 'Norrbotten', url: '/norrbotten', id: 209 },
        gavleborg: { name: 'Gävleborg', url: '/gavleborg', id: 210 },
        kristianstad: { name: 'Kristianstad', url: '/kristianstad', id: 211 },
        goteborg: { name: 'Göteborg', url: '/goteborg', id: 212 },
        blekinge: { name: 'Blekinge', url: '/blekinge', id: 213 },
        kronoberg: { name: 'Kronoberg', url: '/kronoberg', id: 214 },
        vasterbotten: { name: 'Västerbotten', url: '/vasterbotten', id: 215 },
        vasternorrland: { name: 'Västernorrland', url: '/vasternorrland', id: 216 },
        vastmanland: { name: 'Västmanland', url: '/vastmanland', id: 217 },
        uppland: { name: 'Uppland', url: '/uppland', id: 218 },
        vast: { name: 'Väst', url: '/vast', id: 219 },
        halland: { name: 'Halland', url: '/halland', id: 220 },
        orebro: { name: 'Örebro', url: '/orebro', id: 221 },
        ostergotland: { name: 'Östergötland', url: '/ostergotland', id: 222 },
        dalarna: { name: 'Dalarna', url: '/dalarna', id: 223 },
        sodertalje: { name: 'Södertälje', url: '/sodertalje', id: 5283 },
        stockholm: { name: 'Stockholm', url: '/stockholm', id: 701 }
    }
};

export default {
    localChannel: localChannel
};
