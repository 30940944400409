












    import { Vue, Component } from 'vue-property-decorator';
    import UiButton from '@/styleguide/elements/UiButton.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import storage from '@/common/storage';
    import SvgImage from './SvgImage.vue';

    @Component({
        components: {
            UiButton,
            SvgImage,
            UiHeading
        }
    })
    export default class MaintenanceBanner extends Vue {
        private displayBanner: boolean = storage.showMaintenanceBanner !== false;

        private close(): void {
            this.displayBanner = false;
            storage.showMaintenanceBanner = false;
        }
    }
