<template>
    <div class="local-news-item">
        <Thumbnail :url="item.imageUrl" :altText="item.altText" />
        <div class="local-news-item__header">
            <audio-heading
                tag="h3"
                appearance="h6"
                :id="audioId"
                :type="audioType"
                :title="item.title"
                :url="item.url"
                :duration="audioDuration"
                :durationAbbr="audioDurationAbbr"
                :hasAudio="hasAudio">
            </audio-heading>
        </div>
    </div>
</template>

<script>
    import audioHeading from '@/components/shared/audio-heading';
    import Thumbnail from '@/components/shared/Thumbnail';

    export default {
        components: {
            audioHeading,
            Thumbnail
        },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            hasAudio() {
                return this.item.audio !== null;
            },
            audioId() {
                return this.item.audio ? this.item.audio.id : 0;
            },
            audioType() {
                return this.item.audio ? this.item.audio.type : '';
            },
            audioDuration() {
                return this.item.audio ? this.item.audio.duration : '';
            },
            audioDurationAbbr() {
                return this.item.audio ? this.item.audio.durationAbbr : '';
            }
        }
    };
</script>

<style lang="scss" scoped>
    .local-news-item {
        @include flex;
        @include cell-padding-vertical;
        @include inner-divider-horizontal;
    }

    .local-news-item__header {
        flex: 0 1 100%;
    }
</style>
