




















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import LazyImage from '@/components/shared/LazyImage.vue';
    import MenuScroll from '@/components/shared/MenuScroll.vue';
    import { SecondaryMenuLinkItem } from '@/components/secondary-menu/models';
    import SecondaryMenuItem from '@/components/secondary-menu/SecondaryMenuItem.vue';

    interface SecondaryMenuModel {
        title: string;
        titleUrl: string;
        imageUrl: string;
        links: SecondaryMenuLinkItem[];
        altText: string;
    }

    @Component({
        components: {
            LazyImage,
            MenuScroll,
            SecondaryMenuItem
        }
    })
    export default class CompactSecondaryMenu extends Vue {
        @Prop() private readonly dataJson!: string;

        private get model(): SecondaryMenuModel {
            return JSON.parse(this.dataJson);
        }

        get menuLinks(): SecondaryMenuLinkItem[] {
            return this.model.links.map(l => ({
                ...l,
                isActive: this.isActive(l.url)
            }));
        }

        get imageUrl(): string {
            return this.model.imageUrl;
        }

        get altText(): string {
            return this.model.altText;
        }

        get titleLink(): SecondaryMenuLinkItem {
            return {
                title: this.model.title,
                url: this.model.titleUrl,
                isActive: this.isActive(this.model.titleUrl)
            };
        }

        private isActive(url: string): boolean {
            return window.document.location.href.toLowerCase().includes(url?.toLowerCase());
        }
    }
